import React from 'react'
import cx from 'classnames'

import { Href } from 'components/navigation'
import { IconWithText } from 'components/dataDisplay'
import { openSupportModal } from 'compositions/modals'


type FeedbackButtonProps = {
  className?: string
  message?: string
  to?: string
  appealCategoryId?: number
}

const FeedbackButton: React.FC<FeedbackButtonProps> = (props) => {
  const { className, message, to, appealCategoryId } = props

  return (
    <Href className={cx('pointer')} onClick={!to ? () => openSupportModal({ appealCategoryId }) : null} to={to}>
      <IconWithText
        className={className}
        iconName="communication/message_16"
        iconColor="rocky"
        textColor="rocky"
        textProps={{ size: 'c16' }}
        message={message || 'Сообщить о неточности или ошибке'}
      />
    </Href>
  )
}

export default FeedbackButton