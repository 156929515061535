import { useMemo } from 'react'
import { useWebSocket } from 'hooks'
import { useQueryData } from 'hooks/query/context'


const useBusTelemetry = (skip?) => {
  const { host: queryHost } = useQueryData()
  const host = queryHost === 'localhost:4001' ? 'kurchatov.rosatom.city' : queryHost

  const webSocketOptions = useMemo(() => ({
    url: `wss://${host}/transport/rest/ws/bus`,
    disableReconnect: false,
    skip,
  }), [ skip, host ])

  const {
    data,
    isFetching: isBusTelemetryFetching,
    isFailed: isBusTelemetryFailed,
  } = useWebSocket<PublicTransport.BusTelemetry>(webSocketOptions)

  return { busTelemetry: data, isBusTelemetryFetching, isBusTelemetryFailed }
}

export default useBusTelemetry