import React, { useState } from 'react'
import cx from 'classnames'
import { useStations, useZoom } from 'models/publicTransport'
import { useUserLocation } from 'models/mapLayer'
import { useDevice } from 'device'

import { UserLocationMarker } from 'components/map'
import NewMap, { NewMapProps } from 'compositions/map/NewMap/NewMap'
import FeedbackButton from 'compositions/FeedbackButton/FeedbackButton'

import useNavigation from './util/useNavigation'
import { Stations, RoadRoutes, Transport, RoadRoutesLegend } from './components'


type TransportMapProps = {
  className?: string
  isRoadsActive: boolean
  mapHeight: number
  selectedTag?: PublicTransport.TransportTypeTitle | 'ALL'
  isUserMarkerWithTooltip?: boolean
  center?: [number, number]
  initialZoom?: number
  stationId?: string // if we pass stationId then we are displaying one station with bigger
  // icon than the other ones(see StationDetailsPage)
  withFeedback?: boolean
  setSelectedStation: (stationId: string) => void
}

const TransportMap: React.FC<TransportMapProps & NewMapProps & Omit<EditableMap.MapProps, 'form'>> = (props) => {
  const { className, selectedTag = 'ALL', isRoadsActive, mapHeight, isUserMarkerWithTooltip, center,
    initialZoom = 14, stationId, withFeedback, setSelectedStation, ...rest } = props

  const { userLocation, isDone } = useUserLocation()
  const { zoom, center: zoomCenter, isBigIcons, updateZoom, updateCenter } = useZoom(initialZoom,  center || userLocation)

  const { stations, isStationsFetching } = useStations()
  const { filteredStations, searchField } = useNavigation(stations)

  const { isMobile } = useDevice()

  return (
    <div className={className}>
      <div className={cx('relative overflow-hidden')} style={{ height: `${mapHeight}px` }}>
        {
          <NewMap
            center={zoomCenter}
            zoom={zoom}
            onZoomEnd={({ zoom, center }) => {
              updateZoom(zoom)
              updateCenter(center)
            }}
            withFullScreen
            searchField={searchField}
            {...rest}
          >
            <Transport selectedTag={selectedTag} />
            {
              <Stations
                stations={filteredStations}
                isFiltered={filteredStations?.length < stations?.length}
                stationId={stationId}
                isSmallIcons={zoom < 16}
                setSelectedStation={setSelectedStation}
              />
            }
            {
              isRoadsActive && (
                <>
                  <RoadRoutes />
                  <RoadRoutesLegend />
                </>
              )
            }
            <UserLocationMarker userLocation={userLocation} isDone={isDone} withTooltip={isUserMarkerWithTooltip} />
          </NewMap>
        }
      </div>
      {
        withFeedback && (
          <FeedbackButton className="mt-8px" appealCategoryId={1014} />
        )
      }
    </div>
  )
}

export default TransportMap