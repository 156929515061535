import { useMemo } from 'react'
import { useQuery } from 'hooks'


const useTransportItem = (id: string, skip?: boolean) => {
  const queryOptions = useMemo(() => ({
    url: `/transport/rest/env/${id}`,
    skip,
  }), [ id, skip ])

  const { data: transportItem, errors: transportItemErrors,
    isFetching: isTransportItemFetching } = useQuery<ServiceTransport.Transport>(queryOptions)

  return {
    transportItem,
    transportItemErrors,
    isTransportItemFetching,
  }
}

export default useTransportItem