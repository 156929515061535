import { useState, useRef, useCallback, useEffect } from 'react'


const useAnimation = (lat, lon, points, transition) => {
  const [ position, setPosition ] = useState<number[]>([ lat, lon ])

  const animation = useRef([])
  const timeout = useRef(null)

  const animate = useCallback((transition) => {
    const [ position, ...rest ] = animation.current

    if (position) {
      const { lat, lon } = position

      setPosition([ lat, lon ])
      animation.current = rest

      if (rest.length) {
        timeout.current = setTimeout(() => animate(transition), transition)
      }
    }
  }, [])

  const startAnimation = useCallback(({ points, transition }) => {
    animation.current = animation.current.concat(points)

    animate(transition)
  }, [ animate ])

  useEffect(() => {
    setPosition([ lat, lon, transition ])
    startAnimation({ points, transition })
  }, [ lat, lon, points, startAnimation, transition ])

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  return { position }
}

export default useAnimation