import React, { useCallback, useMemo } from 'react'
import { getTransportTypeInfo } from 'models/publicTransport'

import { Marker } from '..'


type TransportMarkerProps = {
  position
  zIndex: number
  isBigIcons?: boolean
  extraData?
  onClick?
  transportType: PublicTransport.TransportTypeTitle
  routeNumber: string
  dir?: number
}

const TransportMarker: React.FC<TransportMarkerProps> = ({ children, position, zIndex,
  isBigIcons, extraData, transportType, routeNumber, dir, onClick }) => {
  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick(extraData)
    }
  }, [ extraData, onClick ])

  const [ lat, lng, transition ] = position

  const transportColor = getTransportTypeInfo(transportType).colorHex

  // const labelDirection = dir && (dir > 0 && dir < 180) ? 'right' : 'left'
  // const rootNodePadding = labelDirection === 'left' ? '4px 16px 4px 8px' : '4px 8px 4px 16px'
  const rootNodePadding = '4px 16px 4px 8px'

  const dirForIcon = dir - 135 // for html in degrees

  const iconHtml = useMemo(() => (`
    <div style="display: inline-flex">
      <div style="width:52px;height:24px;background:${transportColor};padding:${rootNodePadding};font-size:13px;line-height:16px;
      font-weight:500;color:#FFFFFF;border-radius:8px 0px 0px 8px;text-align:center;">
        ${routeNumber}
      </div>
      <div style="position:relative;background:${transportColor};width:24px;height:24px;margin-left:-14px;border-radius:24px;border:2px solid white">
        <div style="position:absolute;background:white;width:8px;height:8px;top:6px;right:6px;border-radius:8px;"></div>
      </div>
    </div>
  `), [ routeNumber, transportColor ])

  const iconWithDirHtml = useMemo(() => (`
  <div style="display: inline-flex">
    <div style="width:52px;height:24px;background:${transportColor};padding:${rootNodePadding};font-size:13px;line-height:16px;
    font-weight:500;color:#FFFFFF;border-radius:8px 0px 0px 8px;text-align:center;">
      ${routeNumber}
    </div>
    <div style="position:relative;background:${transportColor};width:24px;height:24px;margin-left:-14px;border-radius:24px;border:2px solid white">
      <div style="position:absolute;background:${transportColor};width:12px;height:12px;border-bottom:2px solid white;border-right:2px solid white;top:10px;left: 10px;transform:rotate(${dirForIcon}deg);transform-origin: top left;"></div>
      <div style="position:absolute;background:white;width:8px;height:8px;top:6px;left:6px;border-radius:8px;"></div>
    </div>
  </div>
`), [ dirForIcon, rootNodePadding, routeNumber, transportColor ])

  return (
    <Marker
      className="transportMarker"
      position={[ lat, lng ]}
      iconHtml={dir ? iconWithDirHtml : iconHtml}
      zIndex={zIndex}
      iconSize={[ 64, 24 ]}
      iconAnchor={[ 52, 12 ]}
      popupAnchor={[ 120, 2 ]}
      extraData={extraData}
      onClick={handleClick}
    >
      <style>
        {
          `.transportMarker {
            transition: all linear ${transition}ms;
          }
          `
        }
      </style>
      {children}
    </Marker>
  )
}


export default TransportMarker
