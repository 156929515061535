import config from 'config'
import { useAuth } from 'containers/AuthProvider'
import React, { useRef, useMemo, useEffect, useState } from 'react'
import useWS, { ReadyState } from 'react-use-websocket'
import { JsonValue, SendMessage, SendJsonMessage } from 'react-use-websocket/dist/lib/types'


type ReturnType<T> = {
  connectionStatus
  messages: any[]
  data: T
  sendMessage: SendMessage
  sendJsonMessage: SendJsonMessage
  isFetching: boolean
  isFailed: boolean
}

type Props = {
  url: string
  skip?: boolean
  mock?
  disableReconnect?: boolean
  saveMessages?: boolean
  queryParams?: Record<string, string | number>
  modifyResult?: (jsonValue: JsonValue) => any
}

const useWebSocket = <T>({ url, skip = false, mock, disableReconnect = false, saveMessages, modifyResult }: Props): ReturnType<T> => {
  const { token } = useAuth()

  const tokenRef = useRef(token)
  tokenRef.current = token

  const [ connectionCount, setConnectionCount ] = useState(0)

  const messages = useRef([])
  const lastMessage = useRef<JsonValue>({})

  const {
    sendMessage,
    sendJsonMessage,
    // message,
    lastJsonMessage,
    readyState,
  } = useWS(skip ? null : url + `?connectionCount=${connectionCount}`, {
    protocols: (!config.isLegacyAuth && tokenRef.current) ? `${tokenRef.current}` : [],
  })

  useEffect(() => {
    if (lastJsonMessage && saveMessages) {
      if (typeof modifyResult === 'function') {
        messages.current.concat(modifyResult(lastJsonMessage))
      }
      else {
        messages.current.concat(lastJsonMessage)
      }
    }
  }, [ lastJsonMessage, modifyResult, saveMessages ])

  // messages.current = useMemo(() => {
  //   if (lastJsonMessage && saveMessages) {
  //     if (typeof modifyResult === 'function') {
  //       messages.current.concat(modifyResult(lastJsonMessage))
  //     }
  //     else {
  //       messages.current.concat(lastJsonMessage)
  //     }
  //   }
  // }, [ lastJsonMessage, saveMessages, modifyResult ])

  lastMessage.current = lastJsonMessage

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'connecting',
    [ReadyState.OPEN]: 'open',
    [ReadyState.CLOSING]: 'closing',
    [ReadyState.CLOSED]: 'closed',
  }[readyState]

  const isFetching = readyState === ReadyState.CONNECTING
  const isFailed = readyState !== ReadyState.OPEN && readyState !== ReadyState.CONNECTING

  const data = (mock && !isFetching) ? mock : lastJsonMessage

  const timeout = useRef(null)

  useEffect(() => {
    const isRestricted = lastMessage?.current?.response === 'restricted'

    if (readyState === ReadyState.CLOSED && !skip && !isRestricted && disableReconnect) {
      console.error('Closed connection', url)

      if (!saveMessages) {
        timeout.current = setTimeout(() => {
          console.log('Handle reconnect', connectionCount + 1)
          setConnectionCount(connectionCount + 1)
        }, 10000)

        return () => {
          clearTimeout(timeout.current)
        }
      }
    }
    else {
      clearTimeout(timeout.current)
    }
  }, [ url, skip, readyState, saveMessages, disableReconnect, connectionCount ])

  return {
    connectionStatus,
    messages: messages.current,
    data: typeof modifyResult === 'function' ? modifyResult(data) : data,
    sendMessage,
    sendJsonMessage,
    isFetching,
    isFailed,
  }
}


export default useWebSocket
