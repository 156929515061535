import React, { useMemo, useRef } from 'react'
import cx from 'classnames'
import L from 'leaflet'
import { Marker as MarkerLeaflet } from 'react-leaflet'

import s from './Marker.scss'


// position [ lat, lng ]
const Marker = (props) => {
  const {
    children, className, icon, position, zIndex, isHidden, withAnimation, iconHtml,
    iconSize = [ 0, 0 ],
    iconAnchor = [ 0, 0 ],
    popupAnchor = [ 0, 0 ],
    extraData,
    onClick,
  } = props

  const markerRef = useRef(null)

  const markerIcon = useMemo(() => (
    new L.DivIcon({
      className: cx(className, s.marker),
      html: icon ? `<img class="${cx(s.icon, { [s.withAnimation]: withAnimation })}" src="${icon}" />` : iconHtml,
      // iconUrl: icon,
      // iconRetinaUrl: icon,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: isHidden ? new L.Point(0, 0) : new L.Point(...iconSize),
      iconAnchor: new L.Point(...iconAnchor),
      popupAnchor: new L.Point(...popupAnchor),
      extraData,
    })
  ), [ className, icon, withAnimation, iconSize, iconAnchor, popupAnchor, isHidden, extraData, iconHtml ])

  return (
    <MarkerLeaflet
      ref={markerRef}
      position={position}
      icon={markerIcon}
      zIndexOffset={withAnimation ? zIndex + 50 : zIndex}
      // onClick={toggle}
    >
      {children}
    </MarkerLeaflet>
  )
}


export default React.memo(Marker)
