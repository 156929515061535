import { useQuery, useWebSocket } from 'hooks'
import { useMemo } from 'react'


const useRoads = () => {

  const webSocketOptions = useMemo(() => ({
    url: 'wss://test.rosatom.city/trafficJams/rest/ws/road',
    disableReconnect: false,
  }), [])

  const {
    data,
    isFetching: isRoadsFetching,
    isFailed: isRoadsFailed,
  } = useWebSocket<PublicTransport.TrafficJams.Road[]>(webSocketOptions)

  return { roads: data, isRoadsFetching, isRoadsFailed }
}

export default useRoads