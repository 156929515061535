import { useMap } from 'react-leaflet'
import links, { getLinkWithParams } from 'links'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTransportItem } from 'models/serviceTransport'
import { getTransportTypeInfo } from 'models/publicTransport'

import Popup from 'compositions/map/MapContent/components/Popup/Popup'

import { TransportMarker } from '..'

import useAnimation from './util/useAnimation'


type TransportMarkerProps = {
  anim: PublicTransport.BusTelemetry['anims'][0]
  animLength: number
}

const defaultAnimLength = 5 // when backend sends animlength: 0 we use this value(in seconds)

const TransportMarkers: React.FC<TransportMarkerProps> = (props) => {
  const { anim, animLength } = props
  const { id: transportId, lat, lon, anim_points: points, route, dir } = anim || {}
  const transition = (animLength || defaultAnimLength) * 1000 / (points.length || 1)

  const { id: routeId, title: routeNumber, type: { title: transportType }, description } = route
  const transportTypeRuTitle = useMemo(() => getTransportTypeInfo(transportType).ruTitle, [ transportType ])
  const { position } = useAnimation(lat, lon, points, transition)

  const map = useMap()

  const setAllPopupsTransition = useCallback((transition) => {
    const popups = document.querySelectorAll<HTMLDivElement>('.leaflet-popup')
    popups.forEach((popup) => popup.style.transition = transition)
  }, [])

  useEffect(() => {
    map.on('zoomstart', () => setAllPopupsTransition('all linear 200ms'))
    map.on('zoomend', () => setAllPopupsTransition(`all linear ${transition}ms`))
  }, [ map, setAllPopupsTransition, transition ])

  const [ isOpened, setIsOpened ] = useState<boolean>(false)

  const { transportItem, isTransportItemFetching } = useTransportItem(transportId, !isOpened)

  const { gosNum, handicap } = transportItem || {}

  const handlePopupAdd = useCallback((event) => {
    setIsOpened(true)
    event.target._container.style.transition = `all linear ${transition}ms`
  }, [ transition ])

  const handlePopupRemove = useCallback((event) => {
    setIsOpened(false)
    event.target._container.style.transition = 'none'
  }, [])

  return (
    <TransportMarker
      position={position}
      transportType={transportType}
      routeNumber={routeNumber}
      dir={dir}
      zIndex={500}
    >
      <Popup
        data={{
          type: 'busCard',
          title: `${transportTypeRuTitle} №${routeNumber}`,
          subTitle: description,
          text: gosNum,
          withInvalid: handicap,
          transportType,
          to: getLinkWithParams(links.transport.route.details, { routeId }),
          isFetching: isTransportItemFetching,
        }}
        eventHandlers={{
          add: handlePopupAdd,
          remove: handlePopupRemove,
        }}
      />
    </TransportMarker>
  )
}


export default React.memo(TransportMarkers)

