import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'

import { StationMarker } from '..'


type StationsProps = {
  stations: PublicTransport.StationExpanded[]
  stationId: string
  isSmallIcons: boolean
  isFiltered: boolean
  setSelectedStation: (stationId: string) => void
}

const Stations: React.FC<StationsProps> = (props) => {
  const { stations, stationId, isSmallIcons, isFiltered, setSelectedStation } = props
  const map = useMap()

  useEffect(() => {
    if (isFiltered) {
      map.fitBounds(stations.map(({ lat, lon }) => ([ lat, lon ])))
    }
  }, [ isFiltered, map, stations ])

  return (
    <>
      {
        stations?.map(({ id, title, lat, lon }, index) => (
          <StationMarker
            className="ease-transition"
            key={id}
            stationId={String(id)}
            title={title}
            lat={lat}
            lon={lon}
            isSmallIcon={(stationId || isSmallIcons) && String(id) !== stationId}
            setSelectedStation={setSelectedStation}
          />
        ))
      }
    </>
  )
}

export default React.memo(Stations)