import { useMemo } from 'react'
import { useField, useFieldState } from 'formular'


const useNavigation = (stations: PublicTransport.StationExpanded[]) => {
  const searchField = useField<string>({ name: 'Поиск остановки' })
  const { value: search } = useFieldState(searchField)

  const filteredStations = useMemo(() => {
    let result = stations
    if (search) {
      const lowerCasedSearch = search.toLowerCase()
      result = result?.filter(({ title, description }) =>
        [ title, description ].find((prop) => prop?.toLowerCase().includes(lowerCasedSearch)))
    }

    return result
  }, [ search, stations ])

  return { filteredStations, searchField }
}

export default useNavigation