import links from 'links'
import { useMemo } from 'react'
import { useQuery } from 'hooks'
import { useParams } from 'router'


const useStations = ({ skip }: { skip?: boolean } = {}) => {
  const { stationId } = useParams()

  const stationQueryOptions = useMemo(() => ({
    url: '/transport/rest/bus/env/station?expand=true',
    skip,
  }), [ skip ])

  const { data: stations, errors: stationsErrors,
    isFetching: isStationsFetching } = useQuery<PublicTransport.StationExpanded[]>(stationQueryOptions)

  const station = useMemo(() => (
    stationId && stations?.find((station) => station.id === Number(stationId))
  ), [ stationId, stations ])

  return {
    station,
    stations,
    stationsErrors,
    isStationsFetching,
    stationAddLink: links.admin.transport.stations.add,
  }
}


export default useStations
