import React from 'react'

import { Text } from 'components/dataDisplay'


type RoadRoutesLegendProps = {
  className?: string
}

const RoadRoutesLegend: React.FC<RoadRoutesLegendProps> = (props) => {

  return (
    <div className="absolute bg-amelie items-center z-800 flex py-8px px-12px radius-8" style={{ left: '16px', bottom: '16px' }}>
      <div className="bg-matrix radius-8" style={{ width: '8px', height: '8px' }} />
      <Text
        className="ml-6px"
        message="Свободно"
        size="s13-r"
        color="titanic"
      />
      <div className="ml-16px bg-snatch radius-8" style={{ width: '8px', height: '8px' }} />
      <Text
        className="ml-6px"
        message="Затор"
        size="s13-r"
        color="titanic"
      />
      <div className="ml-16px bg-fargo radius-8" style={{ width: '8px', height: '8px' }} />
      <Text
        className="ml-6px"
        message="Пробка"
        size="s13-r"
        color="titanic"
      />
    </div>
  )
}

export default RoadRoutesLegend