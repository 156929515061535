import React, { useEffect, useMemo, useState } from 'react'
import { useBusTelemetry } from 'models/publicTransport'

import { TransportMarkers } from 'layers/publicTransport/components'


type TransportProps = {
  selectedTag: PublicTransport.TransportTypeTitle | 'ALL'
}

const Transport: React.FC<TransportProps> = (props) => {
  const { selectedTag } = props
  const { busTelemetry, isBusTelemetryFetching } = useBusTelemetry()

  const [ isHidden, setIsHidden ] = useState(false)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsHidden(document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  const filteredBusTelemetry = useMemo(() => {
    if (selectedTag === 'ALL') {
      return busTelemetry
    } else {
      const { anims, ...rest } = busTelemetry || {}
      return {
        anims: anims?.filter(({ route }) => route.type.title === selectedTag) || [],
        ...rest,
      }
    }
  }, [ busTelemetry, selectedTag ])

  const { anims, animLength } = filteredBusTelemetry || {}

  if (isHidden) {
    return <></>
  }

  return (
    <>
      {
        !isBusTelemetryFetching && anims?.map((anim, index) => (
          <TransportMarkers
            key={anim.id}
            anim={anim}
            animLength={animLength}
          />
        ))
      }
    </>
  )
}

export default Transport