import React, { Fragment } from 'react'
import { useRoads } from 'models/publicTransport'

import { RoadRoute } from '..'


const RoadRoutes = () => {
  const { roads, isRoadsFetching } = useRoads()

  return (
    <Fragment>
      {
        !isRoadsFetching && roads?.map?.(({ id, avgSpeed, shape, maxSpeed }, index) => Boolean(shape) && (
          <RoadRoute
            key={id}
            positions={shape}
            avgSpeed={avgSpeed}
            maxSpeed={maxSpeed}
            road={roads[index]}
          />
        ))
      }
    </Fragment>
  )
}


export default React.memo(RoadRoutes)

