import React, { useMemo } from 'react'
import { Polyline } from 'react-leaflet'


const getColor = (speed, maxSpeed) => {
  const percent = speed / maxSpeed * 100

  return percent <= 30 ? '#E14761'
    : percent <= 60 ? '#E9A35D'
      : '#57A003'
}

const RoadRoute = ({ positions, avgSpeed, maxSpeed, road }) => {

  const color = useMemo(() => getColor(avgSpeed, maxSpeed > 60 ? 60 : maxSpeed), [ avgSpeed, maxSpeed ])

  return (
    <Polyline
      positions={positions}
      color={color}
    />
  )
}


export default RoadRoute
